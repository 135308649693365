const global_variable = {}

global_variable.phone='+86-12345678999';
global_variable.email='info@example.com';
global_variable.companyName = 'OcleanChemical';

global_variable.contactBox= [
    {"title":'Telephome',"value":'+86-12345678999'},
    {"title":'Whatsapp',"value":'+86-12345678999'},
    {"title":'Wechat',"value":'+86-12345678999'},
    {"title":'E-mail',"value":'info@example.com'}
    ];

global_variable.contactList = [
    {"name":'ri:facebook-fill',"title":'Facebook',"url":'http://www.baidu.com',"color":"#5595EA"},
    {"name":'ri:linkedin-fill',"title":'LinkedIn',"url":'http://www.baidu.com',"color":"#3B67BB"},
    {"name":'ri:youtube-fill',"title":'Youtube',"url":'http://www.baidu.com',"color":"#E13723"},
    {"name":'ri:instagram-fill',"title":'Instagram',"url":'http://www.baidu.com',"color":"#724C3D"},
    {"name":'ri:wechat-fill',"title":'WeChat',"url":'http://www.baidu.com',"color":"#1CD46C"},
    {"name":'ri:whatsapp-fill',"title":'WhatsApp',"url":'http://www.baidu.com',"color":"#78C468"}
    ];

global_variable.menuList = [
    {"name": 'Home', "id":1},
    {"name": 'About', "id":2},
    {"name": 'Products', "id":3},
    // {"name": 'News', "id":4},
    {"name": 'Contact', "id":5},
];

global_variable.productCategory = [
    {'name':'CTEGORY NUMBER1','id':0},
    {'name':'CATEGORY TCCA','id':1},
    {'name':'CATEGORY HAHAHA','id':2}
];

global_variable.productList = [
    [
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'djeisjs-1','category':'CTEGORY NUMBER1','tag':'TAG123',
            'description':'qqqqqqqqqqqqqqqqqqqvgbgfdefrfdvrfvc derfvcdfv cxsdvf cdqqqqqqqqqqqqqqqqqqqqqqqq'+
            'qqssssddddddddddddddddddddddsssssssssssssssdededewftrfdethbv56tr45yhnbgvfr567ujhnbgvfryjhnbvfssssssssss'+
            'sssssssssssssssseeeeeeeeeeeeeeeeeeeeqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'product1-2','category':'CTEGORY NUMBER1','tag':'TAG123',
            'description':'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'edeecec-3','category':'CTEGORY NUMBER1','tag':'TAG123',
            'description':'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'product1-4','category':'CTEGORY NUMBER1','tag':'TAG123',
            'description':'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'cedef44-5','category':'CTEGORY NUMBER1','tag':'TAG123',
            'description':'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        }
    ],
    [
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'dcfewcaz-1','category':'CATEGORY TCCA','tag':'TAG456',
            'description':'qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'edfe32wsw-2','category':'CATEGORY TCCA','tag':'TAG456',
            'description':'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'de3ewsq-3','category':'CATEGORY TCCA','tag':'TAG456',
            'description':'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'vrt5t7urwesa-4','category':'CATEGORY TCCA','tag':'TAG456',
            'description':'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'crf4re2sdxs-5','category':'CATEGORY TCCA','tag':'TAG456',
            'description':'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        }
    ],
    [
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'dedede22wsw-1','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'ed3dcedf45-2','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'crf4r3edws-3','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'fr43esw2wsd-4','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'f4edwe2ssxdd-5','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'67uygwedc','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'g45tgvrewf4','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        },
        {
            'icon':require('../assets/images/products/sdic.jpg'),
            'name':'njt786tgv3','category':'CATEGORY HAHAHA','tag':'TAG789',
            'description':'vfffffffffffvfvfvfvfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            'images':[
                require('../assets/images/products/sdic.jpg'),
                require('../assets/images/products/sdic.jpg')
            ]
        }
    ],
]


export default global_variable
<template>
  <div class="root">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
body{
  font-family: Poppins,Verdana,Tahoma, sans-serif;
  /* background-color: #F5F6FB; */
  scroll-behavior: smooth;
}
.root{
  width: 100%;
  height: 100%;
}
* {
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
}

</style>

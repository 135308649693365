<template>
    <div class="box">
        <div class="cate-type1" v-if="widthType == 2" @mouseleave="closeMe()">
            <span v-for="(item,index) in category" :key="index">
                <span class="fc-333 fz-14 show-block over-pointer fw-bold bg-change line-style" :id="item.id" @click="goProducts(item.id)">{{item.name}}</span>
            </span>
        </div>
    </div>

</template>

<script>
export default ({
    name: 'productCateC',
    data() {
        return {
            // 1:h5, 2:pc, 3: small pc、pad
            widthType: 2,
            category: this.GLOBAL.productCategory,
        };
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods:{
        handleResize () {
            // 页面宽度小于指定宽度时920、840...，调整显示内容
            this.widthType = document.documentElement.clientWidth < 920 ? 3 : 2;
        },
        closeMe(){
            this.$emit('transmit', true)
        },
        goProducts (cateId){
            window.scrollTo(0,0);
            console.log("GO ROUTE PRODUCT "+cateId)
            this.$router.push({
                name:'products',
                params:{
                    pName:cateId,
                }
            });
        },

        
    },
    mounted: function(){
        //页面初始化时判断宽度
        this.widthType = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 1 : 2;
        if(this.widthType == 2){
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
    },

})

</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}
.cate-type1{
    position: absolute;
    right: 195px;
    top: auto;
    z-index: 999;
    width:200px;
    background-color: #FFF;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2),-1px 1px 4px 2px rgba(0, 0, 0, 0.2);
}
.line-style{
    border-bottom: 1px dashed #CCC;
    text-align: center;
    transition: color 1s;
    height: 40px;
    line-height: 40px;
}





</style>
<template>
    
    <div>
        <headC myId=4></headC>
        <navigationC name="News"></navigationC>
        <div>
            <img src="../assets/images/contact/banner4.jpg"/>
        </div>
        <footC></footC>
    </div>
</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import navigationC from '../components/navigation.vue'

export default ({
    name: 'newsV',
    components: {
        headC,
        footC,
        navigationC,
    },
    data() {
        return {
            shrink: false,
            fullWidth: 0,
            fixed: false,
            companyName: this.GLOBAL.companyName,
        };
    },
    
    methods:{
      
    },
    mounted: function(){
        document.title= this.companyName + " | news";
    },
})
   
</script>
<style scoped>

</style>
<template>
    <div class="box">
        <div v-if="shrink" >
            <span class="block-span block-span-first">
                <span class="route-home small-text" @click="goHome">Home</span>
                <span class="small-text"> > {{ curName }} </span>
            </span>
            <span class="block-span big-text"> {{ curName }} </span>
        </div>
        <div v-if="!shrink">
            <div class="not-shrink-item left-div">
                <span class="big-text"> {{ curName }} </span>
            </div>
            <div class="not-shrink-item right-div">
                <span class="route-home small-text" @click="goHome">Home</span><span class="small-text"> > {{ curName }} </span>
            </div>

            
        </div>
    </div>



    
</template>
<script>

export default ({
    name: 'navigationC',
    props: {
        name: String
    },

    data() {
        return {
            curName: this.name,
            shrink: false,
        };
    },
    
    methods:{
      handleResize () {
        // 页面宽度小于920px时，调整显示内容
        this.shrink = document.documentElement.clientWidth < 920 ? true : false;
      },
      goHome(){
        window.scrollTo(0,0);
        this.$router.push('/home');
      }
    },
    mounted: function(){
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
})
   
</script>
<style scoped>
.box{
    width: 100%;
    height: 200px;
    background-color: #D9D9D9;
}
.not-shrink-item{
    margin-top: 75px;
    height: 50px;
    width: 200px;
    line-height: 50px;
}
.left-div{
    float: left;
    margin-left: 5%;
}
.right-div{
    float: right;
    margin-right: 1%;
}
.big-text{
    font-size: 35px;
    color: #3E3E3E;
    font-weight: bold;
}
.small-text{
    font-size: 16px;
    color: #777777;
    font-weight: bold;
}
.route-home:hover{
    cursor: pointer;
}
.block-span{
    display: block;
    margin-left: 3%;
}

.block-span-first{
    padding-top: 58px;
}


</style>
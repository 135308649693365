<template>
    <div class="carousel">
      <div @click="prevSlide" class="arrow left-10">
        <i-left size="100px" fill="white"/>
      </div>
      <div class="carousel-slides">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          :style="{left: index * 100 + '%', 'transform': dynamicstyle}"
          alt="banner"
          @mouseenter = 'stopSlideshow'
          @mouseout = 'startSlideshow'
        />
        <div class="carousel-text">123</div>
      </div>
      <div @click="nextSlide" class="arrow right-10">
        <i-right size="100px" fill="white"/>
      </div>
    </div>
</template>

<script>

export default ({
    name: 'bannerC',
    components: {
    },
    data() {
        return {
            images: [
                require('../assets/images/banner/banner1.jpg'),
                require('../assets/images/banner/banner2.jpg'),
            ],
            dynamicstyle: "", //动态样式
            currentSlide: 0, //播放序号
            interval: Object,
        }
    },
    
    methods:{
        nextSlide() {
            // 每次指针加一
            this.currentSlide = (this.currentSlide + 1) % this.images.length
            this.setStyle();
        },
        prevSlide() {
            // 每次减一，为负数时循环
            this.currentSlide = (this.currentSlide - 1 + this.images.length) % this.images.length;
            this.setStyle();
        },
        // 图片动画
        setStyle() {
            this.dynamicstyle = `translatex(-${this.currentSlide*100}%)`
        },
        // 定时器
        startSlideshow() {
            this.interval = setInterval(() => {
                this.currentSlide = (this.currentSlide + 1) % this.images.length;
                this.setStyle();
            }, 3000)
        },
        stopSlideshow() {
            clearInterval(this.interval)
        }
    },
    mounted: function(){
        // 自动播放动画
        this.startSlideshow();
    }
})
   
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
}
.carousel {
  position: relative;
  width:100%;
  padding-top: 38%;
}
.carousel-slides {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.carousel-slides img {
  display: inline-block;
  position: absolute;
  width: inherit;
  height: 100%;
  transition: 0.5s transform ease-in-out;
}
.carousel-text{
  z-index: 100;
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: red;
}
.carousel-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}
.arrow {
  position: absolute;
  top: 40%;
  opacity: 0.8;
  border: none;
  z-index: 999;
}

.left-10{
  left: 10px;
}
.right-10{
  right:10px;
}


</style>
 
<template>
    <div class="box">
        <div class="float-left" :class="widthType == 2 ? 'width-30':'width-100'">
            <div class="wripper">
                <img  class="logo-60" src="../assets/images/foot/logo.png" />
                <span class="fs-16 fc-FFF show-block padding-u-d-15">Committed to global water treatment products provider</span>
            </div>
        </div>
        <div class="float-left" :class="widthType == 2 ? 'width-20':'width-100'">
            <div class="wripper">
                <span class="fs-20 fc-FFF show-block">
                    Quick Links
                </span>
                <hr class="line-80"/>
                <span v-for="item in menuList" :key="item.id" class="one-item fz-16 fc-FFF show-block">
                    <i-right-two fill="#17d" theme="filled" size="16px"></i-right-two> <a @click="changePage(item.id)">{{ item.name }}</a>
                </span>

            </div>
        </div>
        <div class="float-left" :class="widthType == 2 ? 'width-25':'width-100'">
            <div class="wripper">
                <span class="fs-20 fc-FFF show-block">
                    Contact Us
                </span>
                <hr class="line-80"/>
                <span class="one-item fs-16 fc-FFF show-block"><i-local-two fill="#17D" theme="filled" size="16px" /> Address: {{ address }}</span>
                <span class="one-item fs-16 fc-FFF show-block"><i-phone-telephone fill="#17D" theme="filled" size="16px" /> Phone: <a class="fs-16 fc-FFF over-17d" :href="'tel:'+phone">{{ phone }}</a></span>
                <span class="one-item fs-16 fc-FFF show-block"><i-mail fill="#17D" theme="filled" size="16px" /> Email: <a class="fs-16 fc-FFF over-17d" :href="'mailto:'+email">{{ email }}</a></span>
            </div>
        </div>
        <div class="float-left" :class="widthType == 2 ? 'width-25':'width-100'">
            <div class="wripper">
                <span class="fs-20 fc-FFF show-block">
                    Our Services
                </span>
                <hr class="line-80"/>
                <span class="one-item fs-16 fc-FFF show-block" ><i-time fill="#17D" theme="filled" size="16px" /> Mon - Fri: 8:30 am to 5:00 pm </span>
                <span class="one-item fs-16 fc-FFF show-block" ><i-time fill="#17D" theme="filled" size="16px" /> Saturday: 9:30 am to 1:00 pm </span>
                <span class="one-item fs-16 fc-FFF show-block" ><i-time fill="#17D" theme="filled" size="16px" /> Sunday: Closed </span>
            </div>
        </div>  
        <div class="float-left fs-16 fc-FFF copyRight">
            <span>
                Copyright © 2024 {{ companyName }} Inc | All Rights Reserved 
            </span>
        </div>
        

    </div>

</template>

<script>
export default ({
    name: 'footC',
    data() {
        return {
            // 1:h5, 2:pc, 3: small pc、pad
            widthType: 2,
            address: this.CONSTANT.address,
            phone: this.CONSTANT.phone,
            email: this.CONSTANT.email,
            companyName: this.CONSTANT.companyName,
            menuList: this.CONSTANT.menuList,
        };
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods:{
        handleResize () {
            // 页面宽度小于指定宽度时920、840...，调整显示内容
            this.widthType = document.documentElement.clientWidth < 920 ? 3 : 2;
        },
        changePage(num){
            window.scrollTo(0,0);
            if(num == 1){
                this.$router.push('/home')
            }else if(num == 2){
                this.$router.push('/about')
            }else if(num == 3){
                this.$router.push('/products')
            }else if(num == 4){
                this.$router.push('/news')
            }else if(num == 5){
                this.$router.push('/contact')
            }
        },

    },
    mounted: function(){
        //页面初始化时判断宽度
        this.widthType = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 1 : 2;
        if(this.widthType == 2){
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }

    },

})

</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}
a:hover{
    color: #17D;
}
.box{
    float:left;
    background-color: #0E1E2F;
}


.wripper{
    width: 92%;
    margin-left: 8%;
    margin-top: 30px;
}
.line-80{
    border: 0;
    width: 80px;
    height: 2px;
    background-color: #666;
    margin-bottom: 20px;
}
.one-item{
    line-height: 30px;
}
.copyRight{
    width: 100%;
    margin-top: 20PX;
    border-top: 2px solid #CCC;
    text-align: center;
    line-height: 40px;
}

</style>
import { render, staticRenderFns } from "./footC.vue?vue&type=template&id=06c8d5ff&scoped=true&"
import script from "./footC.vue?vue&type=script&lang=js&"
export * from "./footC.vue?vue&type=script&lang=js&"
import style0 from "./footC.vue?vue&type=style&index=0&id=06c8d5ff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c8d5ff",
  null
  
)

export default component.exports
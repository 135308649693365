const constant_variable = {}

constant_variable.phone='+86-12345678999';
constant_variable.email='info@example.com';
constant_variable.address = '17 Xigonghe Road, Jinan, China';
constant_variable.companyName = 'OcleanChemical';

constant_variable.contactList = [
    {"name":'ri:facebook-fill',"title":'Facebook',"url":'http://www.baidu.com',"color":"#5595EA"},
    {"name":'ri:linkedin-fill',"title":'LinkedIn',"url":'http://www.baidu.com',"color":"#3B67BB"},
    {"name":'ri:youtube-fill',"title":'Youtube',"url":'http://www.baidu.com',"color":"#E13723"},
    {"name":'ri:instagram-fill',"title":'Instagram',"url":'http://www.baidu.com',"color":"#724C3D"},
    {"name":'ri:wechat-fill',"title":'WeChat',"url":'http://www.baidu.com',"color":"#1CD46C"},
    {"name":'ri:whatsapp-fill',"title":'WhatsApp',"url":'http://www.baidu.com',"color":"#78C468"}
];

constant_variable.menuList = [
    {"name": 'Home', "id":1},
    {"name": 'About Us', "id":2},
    {"name": 'Products', "id":3},
    {"name": 'News', "id":4},
    {"name": 'Contact Us', "id":5},
];

export default constant_variable
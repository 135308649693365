<template>
    <div class="search-div">
        <div class="sanjiao" v-if="widthType == 2">
            <i-triangle theme="filled" fill="#F5F6FB" size="20px" class="triangle-unshrink"></i-triangle>
        </div>
        <div class="sanjiao" v-if="widthType != 2">
            <i-triangle theme="filled" fill="#F5F6FB" size="20px" class="triangle-shrink"></i-triangle>
        </div>
        <div class="search-box">
            <input type="text" placeholder="Search..." v-model="searchName" class="search-input">
            <i-search class="search-input-icon" theme="outline" size="20" fill="#17D" @click="search()"/>
        </div>
    </div>

</template>

<script>
export default ({
    name: 'searchC',
    data() {
        return {
            // 1:h5, 2:pc, 3: small pc、pad
            widthType: 2,
            searchName: '',
        };
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize)
    },
    methods:{
        handleResize () {
            // 页面宽度小于指定宽度时920、840...，调整显示内容
            this.widthType = document.documentElement.clientWidth < 920 ? 3 : 2;
        },
        search () {
            if(this.searchName.trim() === ''){
                return;
            }
            this.showBox = false;
            this.$router.push({
                name:'search',
                query:{
                    name:this.searchName,
                }
            });
        },
    },
    mounted: function(){
        //页面初始化时判断宽度
        this.widthType = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 1 : 2;
        if(this.widthType == 2){
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }


    },

})

</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.search-div{
    right:10px;
    margin-top: 5px;
    position: absolute;
    border: none;
    z-index: 1002;
    width: 300px;
    height: 80px;
}
.sanjiao{
    width: 100%;
    height: 16px;
}
.search-box{
    height: 64px;
    border: none;
    background-color: #F5F6FB;
    border-radius: 8px;
}

.search-input{
    border: 1px solid #999999;
    border-radius: 20px;
    width:270px;
    height: 40px;
    background-color: #FDFDFD;
    margin-top: 12px;
    margin-left: 15px;
    font-size: 20px;
    color: #999999;
}
.search-input:focus{
    outline: none;
}
.search-input-icon{
    position: absolute;
    top: 38px;
    right:30px;
}
.search-input-icon:hover{
    cursor: pointer;
}
.triangle-unshrink{
    position: absolute;
    right:38px;
}
.triangle-shrink{
    position: absolute;
    right:80px;
}


</style>
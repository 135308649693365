<template>
    <div>
        <div class="empty-h40" v-if="showTitle == 0"></div>
        <div class="online-info">
            <div class="declare" v-if="showTitle == 1">
                <span class="declare-title">SEND US A MESSAGE</span>
                <span class="declare-content">How can we help? Get free samples, Pricing and a Stock List.</span>
            </div>
            <div class="input-area" :class="!shrink? 'div-h412' : 'div-h612'">
                <input class="empty-h25">
                <div class="input-div" :class="!shrink? 'input-w41' : 'input-w88'">
                    <input class="input-item"  type="text" maxlength="200" placeholder="Name *" v-model="userName"/>
                    <span class="warn-info" v-if="nameWarn">The field is required.</span>
                </div>
                <div class="input-div" :class="!shrink? 'input-w41' : 'input-w88'">
                    <input class="input-item"  type="text" maxlength="200" placeholder="Phone" v-model="phone"/>
                </div>
                <div class="input-div" :class="!shrink? 'input-w41' : 'input-w88'">
                    <input class="input-item"  type="text" maxlength="200" placeholder="Country" v-model="country">
                </div>
                <div class="input-div" :class="!shrink? 'input-w41' : 'input-w88'">
                    <input class="input-item"  type="text"  placeholder="Email *" v-model="email">
                    <span class="warn-info" v-if="emailWarn">The field is required.</span>
                </div>
                <div class="area-div">
                    <textarea class="area-item" maxlength="9000" placeholder="Message *" v-model="message"></textarea>
                    <span class="warn-info" v-if="messageWarn">The field is required.</span>
                </div>
            </div>
            <div class="submit-area">
                <div class="check-add">
                    <span>{{ num1 }} + {{ num2 }} = </span> 
                    <span><input class="inputNum" maxlength="2" type="text>" v-model="inputTotal"/> </span>
                    <span class="refreshSpan" @click="initAddNum"><i-redo fill="#333333"/></span>
                    <span class="warn-info warn-space" v-if="captchaEmpty">Please enter a CAPTCHA value.</span>
                    <span class="warn-info warn-space"  v-if="captchaWarn">Please enter a valid CAPTCHA value.</span>
                </div>
                <div class="submit-info">
                    <span><input class="submitButton" type="button" value="Submit" @click="submit"/></span>
                </div>
            </div>
            <div class="success-div" >
                <div v-if="successSend">
                    <span >Thank you for your message. It has been sent. </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default ({
    name: 'inquiryC',
    props: {
        name: String,
        show: Number,
    },
    data() {
        return {
            shrink: false,
            num1: 0,
            num2: 0,
            realTotal: 0,
            inputTotal: '',
            userName: '',
            phone: '',
            country: '',
            email: '',
            message: '',
            nameWarn: false,
            emailWarn: false,
            messageWarn: false,
            captchaEmpty: false,
            captchaWarn: false,
            successSend: false,
            showTitle: this.show,
            productName: this.name,
        };
    },
    
    methods:{
        handleResize () {
            // 页面宽度小于840px时，调整显示内容
            this.shrink = document.documentElement.clientWidth < 840 ? true : false;
        },
        initAddNum () {
            this.num1 = Math.floor(Math.random() * 8) + 1;
            this.num2 = Math.floor(Math.random() * 4) + 1;
            this.realTotal = this.num1 +  this.num2;
            this.inputTotal = '';
        },
        submit () {
            if(!this.checkEmpty()){
                return;
            }
            //提交数据到后端
            var data = { "product": this.productName , 
                         "name": this.userName,
                         "country": this.country,
                         "email": this.email,
                         "tel": this.phone,
                         "message": this.message,
                        };
            var vm = this;
            this.$axios.post("/contact", data).then(function (response){
                if(response.data.code === 200){ //成功
                    vm.successSend = true;
                    //清空表格内容
                    vm.userName = '';
                    vm.country = '';
                    vm.email = '';
                    vm.phone = '';
                    vm.message = '';
                    vm.initAddNum ();
                }
            });  
        },
        checkEmpty () {
            var result = true;
            if(this.userName.trim() === ''){
                this.nameWarn = true;
                result = false;
            }else{
                this.nameWarn = false;
            }

            if(this.email.trim() === ''){
                this.emailWarn = true;
                result = false;
            }else{
                this.emailWarn = false;
            }

            if(this.message.trim() === ''){
                this.messageWarn = true;
                result = false;
            }else{
                this.messageWarn = false;
            }

            if(this.inputTotal.trim() === ''){
                this.captchaEmpty = true;
                result = false;
            }else{
                this.captchaEmpty = false;
            }
            this.captchaWarn = false;
            if(!this.captchaEmpty){
                if(this.realTotal != this.inputTotal){
                    this.captchaWarn = true;
                    result = false;
                }else{
                    this.captchaWarn = false;
                }
            }
            return result;
        }
    },
    mounted: function(){
        document.title= this.companyName + " | contact";
        this.initAddNum ();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
})
   
</script>
<style scoped>
.empty-h40{
    width: 100%;
    height: 40px;
}
.contact-box-210{
    width: 100%;
    height: 210px;
    background-color: #E2E2E2;
}
.contact-box-840{
    width: 100%;
    height: 840px;
    background-color: #E2E2E2;
}

.box-wrapper{
    width: 92%;
    margin-left: 4%
}
.item-wrapper-25{
    width:25%;
    float:left;
    
}
.item-wrapper-100{
    width:100%;
    float:left;
}

.box-item{
    height: 120px;
    width:80%;
    margin-left: 10%;
    text-align: center;
    margin-top: 50px;
    background-color: #FCFCFC;
    border-radius: 10PX;
    margin-bottom: 40px;
}
.box-title{
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    display: block;
}
.box-content{
    font-size: 14px;
    color: #333333;
    display: block;
}

.online-info{
    width: 100%;
}
.declare{
    width: 100%;
    text-align: center;
    height: 145px;
}
.declare-title{
    display: block;
    color: #007ACC;
    font-size: 35px;
    font-weight: bold;
    height: 100px;
    line-height: 100px;
}
.declare-content{
    display: block;
    font-size: 20px;
    color: #333333;
    height: 45px;
    line-height: 20px;
}

.input-area{
    width: 94%;
    margin-left: 3%;
    background-color: bisque;
}
.empty-h25{
    width: 100%;
    height: 25px;
    background-color: bisque;
    padding: 0 0 0 0;
}
.div-h412{
    height: 412px;
}
.div-h612{
    height: 612px;
}
textarea,input{
    border:none;
    outline:none;
    border-radius: 5px;
    font-size: 20px;
}

.input-div{
    float:left;
    height: 100px;
    margin-left: 6%;
}
.input-w41{
    width:41%;
}
.input-w88{
    width:88%;
}
.input-item{
    width:100%;
    height: 50px;
}
.warn-info{
    height: 20px;
    font-size: 16px;
    color: red;
}
.area-div{
    float:left;
    height: 187px;
    margin-left: 6%;
    width:88%;
}
.area-item{
    width:100%;
    height: 137PX;
    vertical-align: top;
}


.submit-area{
    width: 94%;
    margin-left: 3%;
    background-color: bisque;
    height: 70px;
}

.check-add{
    font-size: 20px;
    width:70%;
    float:left;
    margin-left: 6%;
    line-height: 40px;
    color: #333333;
}
.inputNum{
    width:30px;
    height: 30px;
    color: #333333;
}
.refreshSpan{
    margin-left: 15px;
}
.warn-space{
    margin-left: 10px;
}

.submit-info{
    float:right;
    width:100px;
    height: 60px;
    line-height: 60px;
    margin-right: 6%;
}
.submitButton{
    width: 100px;
    height: 50px;
    font-size: 20px;
    color: #FFFFFF;
    background-color: #007ACC;
}
.success-div{
    width: 94%;
    margin-left: 3%;
    margin-top: 10px;
    text-align: left;
    color: #333333;
    font-size: 16px;
    height: 50px;
}
.success-div div{
    height: 35px;
    line-height: 35px;
    border: 2px solid #1cdd3c;
}
.success-div span{
    margin-left: 15px;
}


</style>
<template>
    <div class="all">
        <headC myId=1></headC>
        <bannerC></bannerC>
        <div class="contact-now"> 
            Please leave your information to us, and we are ready to help you.Call Us at +86-12345678999 (框框按钮Contact Now)
        </div>
        <div class="about-us">
            about us。。。
        </div>
        <div class="products">
            hot products。。。
        </div>
        <footC></footC>
    </div>

</template>
<script>
import headC from '../components/headC.vue'
import footC from '../components/footC.vue'
import bannerC from '../components/banner.vue'
export default ({
    name: 'homeV',
    components: {
        headC,
        footC,
        bannerC,
    },
    data() {
        return {
            companyName: this.GLOBAL.companyName,
        };
    },
    
    methods:{
        
    },
    mounted: function(){
        document.title= this.companyName + " | home";
        // this.$axios.post("/access").then(function (){});   
    },

})
   
</script>
<style scoped>
.all{
    width: 100%;
    margin: 0 0 0 0;
}

.contact-now{
    width: 100%;
    height: 200px;
    background-color: #007ACC;
    line-height: 200px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: white;
}
.about-us{
    width: 100%;
    height: 300px;
    /* border: 1px solid; */
}
.products {
    width: 100%;
    height: 300px;
    /* border: 1px solid; */
}

</style>
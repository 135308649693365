import axios from 'axios'
// import router from '@/router'

const request = axios.create({
    baseURL: "/station",
    timeout: 5000,
})


// 请求拦截器
request.interceptors.request.use(function (config) {
    config.headers.flag=1;
    // 请求拦截
    return config;
}, function (error) {
    // 出现异常，抛出错误对象
    console.log(error);
});


export default request // 导出封装的axios
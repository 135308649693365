//node_modules的@vue模块
import Vue from 'vue'
//相对路径的 App.vue文件
import App from './App.vue'

import router from './router'

import axios from './axios/index'

import global_variable from './global/global'
import constant_variable from './global/constant'
import product_variable from './global/product'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import './assets/styles/font.css'
import './assets/css/common.css'
import './assets/css/font.css'
import './assets/css/style.css'

// 引入所有官方图标
import { install } from '@icon-park/vue/es/all'
//install(Vue) // 默认前缀是 'icon', 例如: 对于`People`这个icon, 组件名字是`icon-people`.
install(Vue, 'i') // 使用自定义前缀'i', 例如: 对于`People`这个icon，组件名字是`i-people`.
import '@icon-park/vue/styles/index.css'

//给生产环境配置的提示消息，
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.GLOBAL = global_variable
Vue.prototype.CONSTANT = constant_variable
Vue.prototype.PRODUCT = product_variable

new Vue({
  //渲染节点
  render: h => h(App),
  router,
  //挂载函数
}).$mount('#app')

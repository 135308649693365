<template>
    <div class="box">
        <div class="list-div">
            <div v-for="(item,index) in contactList" :key="index" :title="item.title" class="one-contact over-pointer" 
                :class="item.title" @click="openUrl(item.url)">
                <Icon :icon="item.name" width="24px" height="24px" color="#FFF"/>
            </div>
            <div v-if="showGoTop" class="go-top over-pointer" @click="goTop()">
                <i-up fill='#FFF' theme="filled" size="30px" ></i-up>
            </div>
        </div>
    </div>

</template>

<script>
import { Icon } from '@iconify/vue2';
export default ({
    name: 'connectC',
    components: {
        Icon,
    },
    data() {
        return {
            // 1:h5, 2:pc, 3: small pc、pad
            widthType: 2,
            contactList: this.CONSTANT.contactList,
            showGoTop: false,
        };
    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods:{
        handleResize () {
            // 页面宽度小于指定宽度时920、840...，调整显示内容
            this.widthType = document.documentElement.clientWidth < 920 ? 3 : 2;
        },
        handleScroll () {
            var scrollTop = 0;
            if (document.documentElement && document.documentElement.scrollTop) {
                scrollTop = document.documentElement.scrollTop;
            } else if (document.body) {
                scrollTop = document.body.scrollTop;
            }
            this.showGoTop = scrollTop >= 200 ? true : false;
        },
        goTop(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
                duration: 1000,
            });
        }
    },
    mounted: function(){
        //页面初始化时判断宽度
        this.widthType = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ? 1 : 2;
        if(this.widthType == 2){
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        }
        window.addEventListener('scroll', this.handleScroll);
    },

})

</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}
.list-div{
    position: fixed;
    width:30px;
    height: 100px;
    top:250px;
    right:0px;
    z-index: 1000;;
}

.one-contact{
    width: 30px;
    height: 30px;
    margin-bottom: 3px;
    text-align: center;
    padding-top: 3px;
}

.Facebook{
    background-color: #5595EA;
}
.LinkedIn{
    background-color: #3B67BB;
}
.Youtube{
    background-color: #E13723;
}
.Instagram{
    background-color: #724C3D;
}
.WeChat{
    background-color: #1CD46C;
}
.WhatsApp{
    background-color: #78C468;
}
.go-top{
    width: 30px;
    height: 30px;
    margin-bottom: 3px;
    text-align: center;
    background-color: #BBB;
}



</style>